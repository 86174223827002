import "./paragraphs.scss"
import { Paragraph } from "./paragraphs"
import React from "react"
import PropTypes from "prop-types"

const Paragraphs = ({ data }) => (
  <div className="paragraph-container">
    {data.map((p, i) => (
      <div className="paragraph" key={`underdog-paragraph${i}`}>
        <h1 className="p-title">{p.title}</h1>
        <p
          className="p-content"
          dangerouslySetInnerHTML={{ __html: p.content }}
        />
      </div>
    ))}
  </div>
)

Paragraphs.propTypes = {
  data: PropTypes.arrayOf(Paragraph),
}

export default Paragraphs
