import PropTypes from "prop-types"

const path = "images/"

export const imagesPaths = [
  `${path}crossfit-altabix.svg`,
  `${path}AGR.svg`,
  `${path}crossfit-dolores.svg`,
  `${path}corex.svg`,
  `${path}crossfit-carrus.svg`,
  `${path}crossfit-result.svg`,
  `${path}crossfit-rabasa.svg`,
]
