import React from "react"
import "./footer.scss"

const social = [
  {
    name: "@theunderdogcreative | instagram",
    url: "https://www.instagram.com/theunderdogcreative/",
    image:
      "https://www.missoulaunitedway.org/sites/missoulaunitedway.org/files/instagram%20white.png",
  },
]

const Footer = () => (
  <footer>
    <span className="contact">
      <a href="mailto:hi@theunderdog.es" rel="nofollow" target="_blank">
        hi@theunderdog.es
      </a>
    </span>
  </footer>
)

export default Footer
