import "./people.scss"
import { Person } from "./people"
import React from "react" 
import PropTypes from "prop-types"

const People = ({ data }) => {

  return (
  <div className={`people-container`}>
    {data.map((person, i) => (
      <div className="person" key={`person${i}`}>
        <div className="avatar">
          <img src={person.image} alt={person.name}/>
        </div>
        <div className="info">
          <h2 className="person-name">{person.name}</h2>
          <span className="person-job">{person.job}</span>
          {person.description && (
            <p
              className="person-description"
              dangerouslySetInnerHTML={{ __html: person.description }}
            />
          )}
        </div>
      </div>
    ))}
  </div>
)}

People.propTypes = {
  data: PropTypes.arrayOf(Person),
}

export default People
