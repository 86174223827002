import "./clients.scss"
import { imagesPaths as images } from "./clients"
import React from "react"
import PropTypes from "prop-types"

const Clients = ({ title }) => {

  const getClientsGrid = (totalClientsPerColumn = 4) => {
    const totalClients = images.length;
    const totalRows = Math.round(totalClients / totalClientsPerColumn)
    const result = [...Array(totalRows)].map((_, row) => {
      const column = images.length < totalClientsPerColumn ? images : images.splice(0, totalClientsPerColumn)
      return (
        <div className="client-row" key={`client-row${row}`}>
          <div className="client-column">
          { column.length && column.map((image, i) => 
            <img src={image} key={`client-image${i}`}/>
          )}
          </div>
        </div>
      )
    })
    return result;
  }
  
  return (
  <div className="clients-container">
    <h1 className="p-title">{title || ""}</h1>
      {getClientsGrid()}
  </div>
)}

Clients.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Clients
