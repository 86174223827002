import React, { Fragment } from "react"
import PropTypes from "prop-types"

import Header from "../../components/header"
import Footer from "../../components/footer"

import './main.scss'

const MainLayout = ({ padding, children }) => (
  <Fragment>
    <Header />
    <main className={padding ? 'padding' : null}>{children}</main>
    <Footer />
  </Fragment>
)

MainLayout.protoTypes = {
  padding: PropTypes.bool,
  children: PropTypes.element
}

export default MainLayout
