import "./header.scss"
import React from "react"
import { Link } from "gatsby"

const Header = () => (
  <header>
    <div className="logo">
      <Link to={"/"}>
        <img src="images/underdog-logo.svg" />
      </Link>
    </div>
  </header>
)

export default Header
