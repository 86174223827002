import { PageProps } from "gatsby"
import React from "react"

import MainLayout from "../layouts/main"
import Wallpaper from "../components/wallpaper"
import Clients from "./../components/clients"
import Paragraphs from "../components/paragraphs"
import People from "./../components/people"

import ParagraphsData from "../data/paragraphs.json"
import PeopleData from "../data/people.json"

import "./index.scss"

const Home = (props: PageProps) => (
  <MainLayout padding>
    <Wallpaper />
    <Paragraphs data={ParagraphsData} />
    <Clients title="Nuestros clientes" />
    <People data={PeopleData} />
  </MainLayout>
)

export default Home
